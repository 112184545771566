@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    color: white;
    background-color: #393130;
    font-family: "Inter", sans-serif;
}